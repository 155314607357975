window.translateMixin = {
  methods: {
    changeLanguage(value, disableReload, force) {
      if (CurrentLanguageDecimal == value && !force) {
        return Promise.resolve();
      }
      var today = new Date();
      var oneYear = new Date(today.getTime() + 365 * 24 * 60 * 60 * 1000);
      var url = window.location.href;

      if (value == 1046)
        url = url.replace('/en-US/', '/').replace('/en-US', '/');

      if (value == 1033 && url.indexOf('/en-US') == -1) {
        url = url.replace('.com.br', '.com.br/en-US');
      }
      document.cookie =
        'lcid=' + value + ';path=/;expires=' + oneYear.toGMTString();
      if (!disableReload) {
        window.location.href = url;
      }
      return Promise.resolve();
    },
    formatUrl(url, nextLang) {
      var idioma =
        nextLang || (CurrentLanguageDecimal !== '1046' ? '/en-US' : '');
      if (idioma == 'pt-BR') idioma = '';

      if (
        _spPageContextInfo.webAbsoluteUrl.includes('/pi') &&
        _spPageContextInfo.webAbsoluteUrl.includes('bmapi')
      ) {
        return `${
          _spPageContextInfo.webAbsoluteUrl.split('/pi')[0]
        }${idioma}/pi/${
          url.includes('/en-US/') ? url.split('/en-US/')[1] : url
        }`;
      } else if (
        _spPageContextInfo.webAbsoluteUrl.includes('/pi') &&
        !_spPageContextInfo.webAbsoluteUrl.includes('bmapi')
      ) {
        return `${
          _spPageContextInfo.webAbsoluteUrl.split('/pi')[0]
        }${idioma}/${url}`;
      } else {
        return `${_spPageContextInfo.webAbsoluteUrl}${idioma}/${url}`;
      }
    },
  },
  data() {
    return {
      isEnglish: CurrentLanguageDecimal == 1033,
      currentLang: CurrentLanguageDecimal,
    };
  },
};
