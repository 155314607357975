require('./forms/form-newsletter');
import VueRecaptcha from 'vue-recaptcha';
Vue.component('vue-recaptcha', VueRecaptcha);

Vue.component('btn-newsletter', {
  mixins: [translateMixin],
  data() {
    return {
      loadForm: false,
      enabledButton: window.innerWidth <= 768,
      buildCaptcha: false,
      isMobile: window.innerWidth <= 768,
    };
  },
  mounted() {
    this.buildCaptcha = true;
  },
  template: `
    <div class="col-sm-4">
        <div class="d-flex mt-3 justify-content-center">
            <a class="bt-newsletter border border-primary p-3 text-center mt-3 text-uppercase font-weight-bold"
                @click="openModal()"
                href="javascript:"
            >{{ currentLang === '1046' ? 'Cadastre seu e-mail' : 'Click here to join our mailing list' }}</a>
        </div>
  
    <!-- Modal -->
    <div class="modal fade" id="modalNewsletter" tabindex="-1" role="dialog" aria-labelledby="modalNewsletterLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title text-center w-100" id="exampleModalLabel">{{ currentLang === '1046' ? 'Cadastre seu e-mail' : 'Sign up your e-mail' }}</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div id="form-rdstation-newsletter" class="modal-body">
                <div class="row justify-content-center m-0 recaptcha">
                    <div class="col-md-12 d-flex justify-content-center" v-if="buildCaptcha && !enabledButton">
                        <vue-recaptcha size="200" :loadRecaptchaScript="true" sitekey="6Ldvy54UAAAAAFXBi5IkkU1FkTn8QTYO4Iw6w6e7" @verify="recaptchaCallback"></vue-recaptcha>
                    </div>
                </div>
                <div class="mt-3" :class="enabledButton && currentLang === '1046' ? 'd-block' : 'd-none'">
                    <div role="main" id="receba-nossos-informativos-3de06f66d9214bee19ed"></div>
                </div>
                <div class="mt-3" :class="enabledButton && isEnglish ? 'd-block': 'd-none'">
                    <div role="main" id="contact-bma-8e89b8ea3cd591412543"></div>
                </div>
            </div>
        </div>
    </div>  
  </div>
  </div>`,
  methods: {
    openModal() {
      this.loadForm = true;
      $('#modalNewsletter').modal();
    },
    onClickCadastrar() {
      this.$refs.newsletter.send();
    },
    recaptchaCallback(result) {
      if (result) this.enabledButton = true;
    },
    onInvalidSubmit() {
      this.enabledButton = false;
    },
  },
});

if (document.querySelectorAll('#btn-newsletter')) {
  new Vue({
    el: '#btn-newsletter',
    template: '<btn-newsletter />',
  });
}

//6LfqCIkUAAAAAI0gWTwq71JsiyfaeUG3SWHM4_bV
