var spMixin = require('../mixins/sp-mixin');

$pnp.setup({
  headers: {
    Accept: 'application/json; odata=verbose',
  },
});

Vue.component('busca-profissionais', {
  template: `
	<div id="busca-profissionais">
		<template v-show="isMenu == true">
			<hr class="mt-4 mb-4 col-md-12" />
			<div class="row">
				<div class="col-md-6">
					<h3 class="sub-title vermelho text-uppercase w-100 font-weight-bold mb-0">
						{{ isEnglish ? 'Search Professionals' : 'Encontre Profissionais' }}
					</h3>
				</div>
				<div class="col-md-6 d-flex align-items-center justify-content-end p-0 ver-todos">
					<div class="d-flex align-items-center info-item">
						<a :href="formatUrl('advogados')" class="ml-3 info-link">{{!isEnglish ? 'Ver todos' : 'See all'}}</a>
					</div>
				</div>
			</div>
		</template>
		<div class="row m-0">
			<ul class="col-md-9 alfabeto">
				<li v-for="letra in letrasAlfabeto">
					<a href="javascript:" @click="filterLetter(letra)" :title="letra" class="active">{{ letra }}</a>
				</li>
			</ul>

			<div class="w-100 menu-busca-profissionais">
				<div class="filtros-busca-profissionais row">
					<div class="form-group col-md-3">
						<input type="text" class="form-control" :placeholder="!isEnglish ? 'Buscar' : 'Search'" v-model="filtros.profissional"  @keypress.enter.prevent="doSearch(); resetPagination();"/>
					</div>
					<div class="form-group col-md-2">
						<select class="custom-select" v-model="filtros.escritorio">
							<option :value="null">{{!isEnglish ? 'Escritórios' : 'Offices'}}</option>
							<option :value="item.Title" v-for="item in escritorios">{{ item.Title }}</option>
						</select>
					</div>
					<div class="form-group col-md-3">
						<select class="custom-select"  v-model="filtros.area">
							<option class="font-weight-bold" :value="null">{{!isEnglish ? 'Áreas' : 'Pratices'}}</option>
							<option :value="item.Title" rel="area" v-for="item in areas">{{ !isEnglish ? item.Title : (item.TitleEN || item.Title) }}</option>
						</select>
					</div>
					<div class="form-group col-md-2">
						<select class="custom-select" v-model="filtros.tipoProfissional">
							<option :value="null">{{ !isEnglish ? 'Profissionais' : 'Professionals' }}</option>
							<option :value="item.Title" v-for="item in tipoProfissionais" v-if="(sociedade == 'LAW') || (sociedade == 'PI' && item.Title != 'Consultor')">{{ !isEnglish ? item.Title : (item.TitleEN || item.Title) }}</option>
						</select>
					</div>
					<div class="form-group col-md-2">
						<select class="custom-select" v-model="filtros.idioma">
							<option :value="null">{{!isEnglish ? 'Idioma' : 'Language'}}</option>
							<option :value="item.Title" v-for="item in idiomaProfissional" v-if="item.Ativo">{{ !isEnglish ? item.Title : (item.TitleEN || item.Title) }}</option>
						</select>
					</div>
					<div class="row w-100 justify-content-end filtro-buttons">
						<div class="form group col-md-2 pr-0">
							<button type="button" class="btn btn-primary text-uppercase font-weight-bold" v-on:click="resetPagination();">{{ !isEnglish ? 'Buscar' : ('Search' || 'Buscar') }}</button>
						</div>
						<div class="form group col-md-2 pr-0">
							<button type="button" class="btn btn-primary bg-primary text-uppercase font-weight-bold" v-on:click="clearSearch();">{{ !isEnglish ? 'Limpar' : ('Clear' || 'Limpar') }}</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	`,
  mixins: [spMixin, translateMixin],
  created: function () {
    this.persistSearch();
    if (!this.isMenu) {
      this.doSearch();
    }
    this.getEscritorios();
    this.getAreas();
    /*if (SociedadeValue == 'LAW') {
			this.getSetores()
			this.getDesks()
		}*/
    this.getTipoProfissional();
    this.getIdiomaProfissional();
  },
  props: {
    isMenu: { default: false },
  },
  methods: {
    onChangeArea(e) {
      Vue.set(this.filtros, 'area', null);
      Vue.set(this.filtros, 'setor', null);
      Vue.set(this.filtros, 'desk', null);

      var val = e.target.value;
      var target =
        e.target.options[e.target.options.selectedIndex].getAttribute('rel');
      Vue.set(this.filtros, target, val);
    },
    persistSearch() {
      Object.keys(this.filtros).map((filtro) => {
        var value = this.getHashParam(filtro);
        value = value ? decodeURIComponent(value) : null;

        Vue.set(this.filtros, filtro, value);
      });
    },
    getHashParam(name) {
      if (name) {
        var hash = window.location.hash.substr(1);

        var result = hash.split('&').reduce(function (result, item) {
          var parts = item.split('=');
          result[parts[0]] = parts[1];
          return result;
        }, {});

        return result[name];
      }
    },
    doSearch() {
      var query = [];
      var paramURL = [];

      if (SociedadeValue == 'PI') {
        query.push("bmaAuxSociedade:'PI'");
      }
      let keyword = this.filtros.keyWord || this.filtros.profissional;

      if (keyword) {
        query.push(`(bmaNomeComp:"*${keyword}*" OR mfAdvTags:"*${keyword}*")`);
        //query.push(`'*${this.filtros.profissional}*'`);
        paramURL.push(`profissional=${keyword}`);
      }

      if (this.filtros.escritorio) {
        query.push(`bmaUnidade:'${this.filtros.escritorio}'`);
        paramURL.push(`escritorio=${this.filtros.escritorio}`);
      }

      if (this.filtros.area) {
        query.push(`bmaAuxAtuacao:"*${this.filtros.area}*"`);
        paramURL.push(`area=${this.filtros.area}`);
      }

      if (this.filtros.setor) {
        query.push(`bmaAuxSetores:'${this.filtros.setor}'`);
        paramURL.push(`setor=${this.filtros.setor}`);
      }

      if (this.filtros.desk) {
        query.push(`bmaAuxDesks:'${this.filtros.desk}'`);
        paramURL.push(`desk=${this.filtros.desk}`);
      }

      if (this.filtros.tipoProfissional) {
        if (this.filtros.tipoProfissional === 'Advogado') {
          query.push(
            `(bmaAuxTipoProf:'Advogada' OR bmaAuxTipoProf:'Advogado')`
          );
        } else if (this.filtros.tipoProfissional === 'Sócio') {
          query.push(`(bmaAuxTipoProf:'Sócio' OR bmaAuxTipoProf:'Sócia')`);
        } else if (this.filtros.tipoProfissional === 'Consultor') {
          query.push(
            `(bmaAuxTipoProf:'Consultor' OR bmaAuxTipoProf:'Consultora')`
          );
        } else {
          query.push(`bmaAuxTipoProf:'${this.filtros.tipoProfissional}'`);
        }
        paramURL.push(`tipoProfissional=${this.filtros.tipoProfissional}`);
      }

      if (this.filtros.letra) {
        query.push(`bmaPL:'${this.filtros.letra}'`);
        paramURL.push(`letra=${this.filtros.letra}`);
      }

      if (this.filtros.idioma) {
        query.push(`bmaAuxIdioma:'${this.filtros.idioma}'`);
        paramURL.push(`idioma=${this.filtros.idioma}`);
      }

      query = query.join(' AND ');
      paramURL = paramURL.join('&');

      this.$emit('change-query', query);
      if (this.isMenu) {
        window.location.href =
          _spPageContextInfo.siteAbsoluteUrl +
          `/paginas/advogados.aspx#` +
          paramURL;
      } else {
        window.location.hash = paramURL;
      }
      console.log(paramURL);
    },
    clearSearch() {
      this.$emit('reset-page');

      this.filtros = Object.keys(this.filtros).reduce((acc, key) => {
        acc[key] = null;
        return acc;
      }, {});

      this.doSearch();
    },
    resetPagination() {
      this.$emit('reset-page');
      this.doSearch();
    },
    getEscritorios() {
      var web = new $pnp.Web(_spPageContextInfo.siteAbsoluteUrl);
      return web
        .getList(this.escritoriosURL)
        .items.select('*')
        .orderBy('Ordem', true)
        .top(100)
        .usingCaching()
        .get()
        .then((results) => {
          Vue.set(this, 'escritorios', results);
        });
    },
    getAreas() {
      var title = this.isEnglish ? 'TitleEN' : 'Title';

      var web = new $pnp.Web(_spPageContextInfo.siteAbsoluteUrl);
      return web
        .getList(this.areasURL)
        .items.filter(
          `Title ne 'Comércio Internacional' and FiltrarPesquisa eq 'Sim' and AuxSociedade eq '${SociedadeValue}' and Ativo eq 1`
        )
        .select('*, ItemPai/Title, ItemPai/Id')
        .orderBy(`${title}`)
        .expand('ItemPai')
        .top(100)
        .usingCaching()
        .get()
        .then((results) => {
          results = results.filter((item) => {
            if (!item.ItemPaiId) {
              this.areas.push(item);
            }
          });
        });
    },
    getSetores() {
      var web = new $pnp.Web(_spPageContextInfo.siteAbsoluteUrl);
      return web
        .getList(this.setoresURL)
        .items.select('*')
        .orderBy('Title', true)
        .top(100)
        .usingCaching()
        .get()
        .then((results) => {
          Vue.set(this, 'setores', results);
        });
    },
    getDesks() {
      var web = new $pnp.Web(_spPageContextInfo.siteAbsoluteUrl);
      return web
        .getList(this.desksURL)
        .items.select('*')
        .orderBy('Title', true)
        .top(100)
        .usingCaching()
        .get()
        .then((results) => {
          Vue.set(this, 'desks', results);
        });
    },
    getTipoProfissional() {
      var web = new $pnp.Web(_spPageContextInfo.siteAbsoluteUrl);
      var filter = [`Title ne 'Profissional'`, `Ativo eq 1`];
      if (SociedadeValue == 'PI') {
        filter.push(`Title ne 'Consultor'`);
      }

      var title = this.isEnglish ? 'TitleEN' : 'Title';

      return web
        .getList(this.tipoProfissionaisUrl)
        .items.select('*')
        .filter(filter.join(' and '))
        .orderBy(`${title}`)
        .top(100)
        .usingCaching()
        .get()
        .then((results) => {
          Vue.set(this, 'tipoProfissionais', results);
        });
    },
    getIdiomaProfissional() {
      var title = this.isEnglish ? 'TitleEN' : 'Title';

      var web = new $pnp.Web(_spPageContextInfo.siteAbsoluteUrl);
      return web
        .getList(this.idiomaProfissional)
        .items.select('*')
        .orderBy(`${title}`)
        .top(50)
        .usingCaching()
        .get()
        .then((results) => {
          Vue.set(this, 'idiomaProfissional', results);
        });
    },
    filterLetter(currentLetra) {
      this.filtros.letra = currentLetra;
      this.doSearch();
    },
  },
  data: function () {
    return {
      escritorios: null,
      areas: [],
      setores: [],
      desks: [],
      tipoProfissionais: null,
      idiomaProfissional: null,
      escritoriosURL:
        _spPageContextInfo.siteServerRelativeUrl + 'lists/unidades/',
      areasURL:
        _spPageContextInfo.siteServerRelativeUrl + 'lists/AreasAtuacao/',
      setoresURL: _spPageContextInfo.siteServerRelativeUrl + 'lists/Setores/',
      desksURL: _spPageContextInfo.siteServerRelativeUrl + 'lists/Desks/',
      tipoProfissionaisUrl:
        _spPageContextInfo.siteServerRelativeUrl + 'lists/AuxTipoProfissional/',
      idiomaProfissional:
        _spPageContextInfo.siteServerRelativeUrl + 'lists/AuxIdiomas/',
      profissional: null,
      sociedade: SociedadeValue,
      filtros: {
        escritorio: null,
        area: null,
        setor: null,
        desk: null,
        tipoProfissional: null,
        letra: null,
        profissional: null,
        idioma: null,
      },
    };
  },
});

if (document.querySelector('#busca-profissionais')) {
  var app = new Vue({
    el: '#busca-profissionais',
    template: '<busca-profissionais/>',
  });
}
