$pnp.setup({
    headers: {
        "Accept": "application/json; odata=verbose"
    }
});

Vue.component('menu-adm-content', {
    template: `
        <div class="ux-edit" v-if="isGroupGestor" v-show="contentAdmin.length">
            <h3 class="text-white text-uppercase">Opções de Gerenciamento</h3>
            <div class="row">
                <div class="col-sm-11">
                    <ul class="nav">
                        <li class="nav-item" v-for="link in contentAdmin">
                            <a class="nav-link bg-light" :href="link.URL" :title="link.Title">{{ link.Title }}</a>
                        </li>
                    </ul>
                </div>
                <div class="col-sm-1 text-center button">
                    <a href="javascript:" @click="toggleMenu($event.target)" title="Ocultar" class="text-white font-weight-bold">
                        <span class="icon" v-if="isClosed">+</span>
                        <span class="icon" v-else>-</span>
                    </a>
                </div>
            </div>
        </div>
    `,
    data: function() {
        var listName = 'MenuAdminContent'
        return {
            listName: listName,
            listUrl: _spPageContextInfo.siteServerRelativeUrl + '/lists/' + listName + '',
            contentAdmin: [],
            isGroupGestor: null,
            isClosed: false,
            groupUserLogado: []
        }
    },
    created: function() {
        this.getData()
            .then(this.applyGetData, this.threatError.bind(this, 'erro ao obter informações da lista ' + this.listName + ''))
            .then(this.checkGroup)
    },
    methods: {
        getData: function() {
            var pageID = _spPageContextInfo.pageItemId;
            var siteUrl = ''
            if(_spPageContextInfo.siteServerRelativeUrl.match(/pi/i)){
                siteUrl
            }
            var web = new $pnp.Web(_spPageContextInfo.siteAbsoluteUrl);
            return web.getList(this.listUrl).items
                .filter(`AuxSociedade/Title eq '${SociedadeValue}' and AuxPaginas/Id eq ${pageID}`)
                .select('*,AuxPaginas/Id, AuxSociedade/Title')
                .expand(`AuxPaginas, AuxSociedade`)
                .orderBy('Ordem', true)
                //.top()
                .get()
        },
        applyGetData: function(data) {
            this.contentAdmin = data;
        },
        applyEvents: function() {

        },
        threatError: function(msg, data) {
            console.error('error - ' + msg + ' - ' + data);
        },
        checkGroup: function() {
            if (!_spPageContextInfo.userId) return Promise.resolve();
            var web = new $pnp.Web(_spPageContextInfo.siteAbsoluteUrl);
            return web.siteUsers.getById(_spPageContextInfo.userId).expand('groups')
                .get()
                .then(userGroups => {
                    this.$set(this, 'groupUserLogado', userGroups.Groups.results)
                    return userGroups.Groups.results.find(grupo => {
                        if (grupo.Title === 'BMA - Web Site - Gestor de Conteúdo') {
                            this.isGroupGestor = true
                        }
                    })
                })
        },
        toggleMenu(el){
            if($('.ux-edit').hasClass('minimized')){
                $('.ux-edit').removeClass('minimized');
                $(el).parent().removeClass('close');
                this.isClosed = false
            }else{
                $('.ux-edit').addClass('minimized');
                $(el).parent().addClass('close');
                this.isClosed = true
            }
        }
    }
})

var app = new Vue({
    el: '#menu-adm-content',
    template: '<menu-adm-content/>'
})