$pnp.setup({
  headers: {
    Accept: 'application/json; odata=verbose',
  },
});

Vue.component(`loading`, {
  data() {
    return {
      isLoaded: false,
      logo: `${_spPageContextInfo.webAbsoluteUrl}/style%20library/Images/BMA_marca_bmaadv_horizontal.png`,
    };
  },
  template: `
    <div id="loading-page" class="bg-white position-fixed" :class="{'loaded': isLoaded}">
        <img :src="logo" alt="Logo BMA" height="50" class="mb-4" />
        <div class="loader"></div>
    </div>
    `,
  methods: {
    loadingPage() {
      $(`body`).addClass(`loading`);
      setTimeout(() => {
        this.isLoaded = true;
        $(`#loading-page`).animate(
          {
            opacity: '0',
          },
          100
        );
        $(`#loading-page`).remove();
        $('.bma-webpart').removeClass('d-none');
        $(`body`).removeClass(`loading`);
      }, 1000);
    },
    getImage() {
      return `${_spPageContextInfo.webServerRelativeUrl}Style%20Library/Images/loading.gif`;
    },
  },
  created() {
    this.loadingPage();
  },
});

var app = new Vue({
  el: `#loading`,
  template: `<loading />`,
});
