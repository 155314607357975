
Vue.component('form-field',{
    template: `<element v-if="field">
        <label class="font-weight-bold d-block" v-if="!field.HideLabel">
            {{ field.Title }} <span v-if="field.Required" class="text-danger">*</span>
        </label>
        <template v-if="field.TypeAsString === 'Lookup' || field.TypeAsString === ' '">
            <v-select
				:debounce="250"
				:options="field.Choices"
                :multiple="field.AllowMultipleValues"
                :value.sync="field.Value"
                :label="field.LookupField"
                :on-change="onChangeLookup"
            >
                <span slot="no-options">
                    Nenhum resultado encontrado
                </span>
			</v-select>
        </template>
        <template v-else-if="field.TypeAsString === 'LookupMulti' && field.AllowMultipleValues === true">
            <div class="form-check form-check-inline">
                <input type="checkbox" class="form-check-input" id="todos" @click="selectAll(field)" :checked="field.Choices.length == field.Value.length" />
                <label class="form-check-label" for="todos">Todos</label>
            </div>
            <div class="form-check form-check-inline" v-for="choice in field.Choices">
                <input class="form-check-input" 
                        type="checkbox" 
                        :id="choice.Id" 
                        :value="choice.Id" 
                        v-model="field.Value" 
                />
                <label class="form-check-label" :for="choice.Id">{{choice.Title}}</label>
            </div>
        </template>
        <template v-else-if="field.TypeAsString === 'User'">
            <people-picker :model="field" @change="onChange" :group-name="field.GroupName" :group-id="field.GroupId"/>
        </template>
        <template v-else-if="field.TypeAsString === 'DateTime'">
            <v-date-picker
                v-if="!field.ReadOnlyField"
                popover-visibility="focus"
                mode="single"
                v-model="field.Value"
                class="d-block"
                :min-date="minDate"
                >
                <input
                    type='text'
                    slot-scope='{ inputValue, updateValue }'
                    :value='inputValue'
                    @change='updateValue($event.target.value, { formatInput: true, hidePopover: false })'
                    class='form-control'
                >
            </v-date-picker>
            <input v-else :disabled="field.ReadOnlyField" class="form-control" type="text" 
            v-model="moment(field.Value).format('DD/MM/YYYY')" />
        </template>
        <template v-else-if="field.TypeAsString === 'Choice'">
            <template v-if="field.EditFormat === 0">    
                <select class="custom-select" v-model="field.Value" @change="onChange">
                    <option :value="null">Selecione</option>
                    <option :value="choice" v-for="choice in field.Choices.results">
                        {{ choice }}
                    </option>
                </select>
            </template>
            <template v-if="field.EditFormat === 1">    
                <div class="form-check" v-for="choice in field.Choices.results">
                    <input class="form-check-input" 
                        v-model="field.Value" @change="onChange" 
                        type="radio"
                        :value="choice" 
                        :id="choice+field.InternalName">
                    <label class="form-check-label" :for="choice+field.InternalName">
                        {{ choice }}
                    </label>
                </div>
            </template>
        </template>
        <template v-else-if="field.TypeAsString === 'MultiChoice'">
            <div class="form-check" v-for="choice in field.Choices.results">
                <input class="form-check-input" 
                    v-model="field.Value" @change="onChange" 
                    type="checkbox"
                    :value="choice" 
                    :id="choice+field.InternalName">
                <label class="form-check-label" :for="choice+field.InternalName">
                    {{ choice }}
                </label>
            </div>
        </template>
        <template v-else-if="field.TypeAsString === 'Boolean'">
            <div class="form-check form-check-inline">
                <input class="form-check-input" 
                    v-model="field.Value" @change="onChange" 
                    type="radio"
                    :value="true" 
                    :id="'sim'+field.InternalName">
                <label class="form-check-label" :for="'sim'+field.InternalName">
                    Sim
                </label>
            </div>
            <div class="form-check form-check-inline">
                <input class="form-check-input"
                    v-model="field.Value" @change="onChange"
                    type="radio"
                    :value="false"
                    :id="'nao'+field.InternalName">
                <label class="form-check-label" :for="'nao'+field.InternalName">
                    Não
                </label>
            </div>
        </template>
        <template v-else-if="field.TypeAsString === 'Note'">
            <textarea :rows="field.NumberOfLines" type="text" class="form-control" v-model="field.Value" @change="onChange"/>
        </template>
        <template v-else>
            <input type="text" class="form-control" v-model="field.Value" @change="onChange" :disabled="field.ReadOnlyField"/>
        </template>
        <a href="#" 
            v-if="field.Description" 
            class="btn btn-secondary position-absolute bt-tooltip" 
            data-placement="top" 
            data-toggle="tooltip"
            :title="field.Description">
            <i class="fas fa-info text-white"></i>
        </a>
    </element>`,
    props: {
        field: { required: true },
        minDate: { default: null }
    },
    created() {
        if(!this.field) return false;
        
        if (this.field.TypeAsString === 'Lookup' || this.field.TypeAsString === 'LookupMulti') {
            this.$set(this.field, 'Choices', []);
            if(this.field.TypeAsString === 'LookupMulti'){
                this.$set(this.field, 'Value', this.field.Value || []);
            }else {
                this.$set(this.field, 'Value', this.field.Value || null);
            }
            this.loadLookupChoices();
        }
    },
    methods: {
        loadLookupChoices() {
            let select = `Id, ${this.field.LookupField}`;
            select = this.field.LookupSelect ? `${select},${this.field.LookupSelect}` : select;

            let call = $pnp.sp.web.lists.getById(this.field.LookupList).items
                .select(select)
                .top(1000)
                .orderBy(this.field.LookupOrder || this.field.LookupField);

            call = this.field.LookupExpand ? call.expand(this.field.LookupExpand) : call;

            if (this.field.LookupFilter) {
                let filter = this.field.LookupFilter;

                if (this.isFunction(filter)) {
                    filter = this.field.LookupFilter.apply(this);
                }
                call = call.filter(filter);
            }

            return call.get()
                .then(choices => this.$set(this.field, 'Choices', choices));

        },
        selectAll(field) {
            var hasTodos = field.Choices.length == field.Value.length;

            if (hasTodos) {
                Vue.set(field, 'Value', [])
            }else {
                Vue.set(field, 'Value', field.Choices.map( choice => choice.Id))
            }
        },
        isFunction(functionToCheck) {
            return functionToCheck && {}.toString.call(functionToCheck) === '[object Function]';
        },
        onChange() {
            this.$emit('change', this.field.Value);
        },
        onChangeLookup(val) {
            Vue.set(this.field, 'Value', val);
            this.onChange();
        }
    },
    data: function(){
        return {
            moment: moment,
            empresaChoices: []
        }
    },
    watch: {
        'field.LookupFilter': {
            handler: function(val, oldVal) {
                this.loadLookupChoices();
            },
            deep: true
        }
    }    
})