const formFields = require('./form-fields');
const spFormMixin = require('../../mixins/sp-mixin');
const formMixin = require('../../mixins/form-mixin');
const Swal = require('sweetalert2');
require('../../polyfill');

$pnp.setup({
    headers: {
        "Accept": "application/json; odata=verbose"
    }
});


Vue.component('form-newsletter', {
    components: { formFields },
    template: `<element v-if="fields">
        <div class="contact">

        <div class="row">
            <div class="col-md-12">
                <div class="form-group">
                <form-field :field="fields.Title"/>
                </div>
            </div>

            <div class="col-md-12">
                <div class="form-group">
                <form-field :field="fields.Email"/>
                </div>
            </div>

            <div class="col-md-12" v-if="showButtons">
                <div class="form-group">
                <button class="rounded" type="button" @click="send">Cadastre seu email</button>
                </div>
            </div>

            </div>

        </div>

</element>
    `,
    mixins: [spFormMixin, formMixin, translateMixin],
    data() {
        return {
            listUrl: _spPageContextInfo.siteServerRelativeUrl + '/lists/Newsletter/',
            listName: 'Newsletter',
            distPath: _spPageContextInfo.webAbsoluteUrl + '/style%20library',
            fields: null,
            moment: moment,
            disabledButton: false,
            enabledButton: false,
            swal: Swal
        }
    },
    created() {
        this.loadFormType()
            .then(this.getFields)
            .then(this.applyFields.bind(this), this.threatError.bind(this, 'erro ao obter informações da lista ' + this.listName + ''))
    },
    props: {
        showButtons: { type: Boolean, default: true }
    },
    methods: {
        getFields() {
            var web = new $pnp.Web(_spPageContextInfo.siteAbsoluteUrl);
            return web.getList(this.listUrl).fields
                .filter(`CanBeDeleted eq true or InternalName eq 'Title'`)
                .get()
        },
        applyFields(fields) {
            this.fields = fields.reduce((fieldObj, field) => {
                field.Placeholder = field.Description || field.Title;
                field.Value = null;
                field.Invalid = false;
                fieldObj[field.InternalName] = field;
                return fieldObj;
            }, {});
            return fields;
        },
        loadFormType() {
            return Promise.resolve()
        },
        send() {
            this.showLoading();
            let invalidFields = this.getInvalidFields(this.fields);
            if (invalidFields.fields.length) {
                this.closeLoading();
                this.$emit('invalid-submit');
                this.invalidateFields(invalidFields.fields);
                return this.swal({
                    title: "Ops!",
                    html: `<span class="font-weight-bold mb-3">${!this.isEnglish ? 'Os dados informados são inválidos: ' : 'The data entered is invalid:'}</span> 
                        ${ invalidFields.msgsErro.map(erro => `<div class="d-flex justify-content-center align-items-center error-item"><div class="error-dash"></div><span class="d-block">${erro}</span></div>`).join('')}`,
                    type: "error"
                }).then(() => {
                    this.clearForm()
                });
            } else {
                this.saveSolicitacao()
                    .then(this.afterSave)
                    .catch(this.threatError.bind(this, 'erro ao salvar solicitação'));
            }
        },
        saveSolicitacao(email) {
            var web = new $pnp.Web(_spPageContextInfo.siteAbsoluteUrl);
            return web.getList(this.listUrl).items.add(this.getDataToSave())
        },
        afterSave() {
            this.swal({
                title: !this.isEnglish ? 'Obrigado!' : 'Thank you!',
                text: !this.isEnglish ? 'Seu e-mail foi cadastrado com sucesso' : 'Your e-mail has been successfully registered',
                type: "success",
            }).then(() => {
                this.clearForm(),
                window.location.replace("/Paginas/default.aspx");
            })
        },
        clearForm() {
            Object.keys(this.fields).map(fieldName => {
                let field = this.fields[fieldName];

                Vue.set(field, 'Value', null);
                grecaptcha.reset();
            })
        },
        invalidateFields(invalidFields) {
            invalidFields.forEach(fieldName => {
                Vue.set(this.fields[fieldName], 'Invalid', true);
            });
        },
        showLoading(title, msg) {
            var defaultTitle = !isEnglish ? 'Aguarde...' : 'Wait...';
            title = title || defaultTitle;
            msg = msg || 'Por favor aguarde alguns instantes';
            return this.swal({
                title,
                html: msg,
                allowOutsideClick: false,
                showConfirmButton: false
            });
        },
        closeLoading() {
            return this.swal.close();
        },
        threatError(msg, data) {
            console.error('error - ' + msg + ' - ' + data);
            this.swal({
                title: 'Ops!',
                text: !this.isEnglish ? 'Algo deu errado. Tente novamente' : 'Something\'s\ wrong. Try again',
                type: "error",
            });
        }
    }
})