window.pnpMixin = {
	methods: {
		getCurrentUser: function () {
		var web = new $pnp.Web(_spPageContextInfo.siteAbsoluteUrl);
		   return web.siteUsers.getById(_spPageContextInfo.userId).expand('groups').get();
		},
		isUserMemberOf: function (groupName, groups){
			return [].concat(groups).some(function (group) {
				return group.Title === groupName;
			});
		},
		getRelativeUrl: function(link){
			return $pnp.util.makeUrlAbsolute(link).replace(location.protocol + '//' + location.hostname, '')
		},
		truncateString: function(str, length){
			return !str ? "" : str.length > length ? str.substring(0, length - 3) + '...' : str
		},
		getPicture: function(user){
			return _spPageContextInfo.webAbsoluteUrl + '/_layouts/15/userphoto.aspx?size=L&username='+user+''
		},
		getList: function(settings) {
			var web = new $pnp.Web(_spPageContextInfo.siteAbsoluteUrl);
			var request = web.getList(settings.listUrl).items
			settings.select ? 	request.select(settings.select) : null
			settings.orderBy ? 	request.orderBy(settings.orderBy, settings.orderByAsc) : null
			settings.filter ? 	request.filter(settings.filter) : null
			settings.expand ? 	request.expand(settings.expand) : null
			settings.top ? 		request.expand(settings.top) : null

			return request.get()
		}
	},
	data() {
		return {
			isPi: SociedadeValue == 'PI' ? true : false
		}
	},
}