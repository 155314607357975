// verificando se o SociedadeValue está vazio e setando como LAW
if (window.location.href.match(/\/pi+/g)) {
  SociedadeValue = `PI`;
} else {
  if (SociedadeValue == '') {
    SociedadeValue = `LAW`;
  }
}

/////////////////////////////////////////////
//  POLYFILL PROTOTYPE ARRAY FIND
/////////////////////////////////////////////
if (!Array.prototype.find) {
  Array.prototype.find = function (predicate) {
    if (this === null) {
      throw new TypeError('Array.prototype.find called on null or undefined');
    }
    if (typeof predicate !== 'function') {
      throw new TypeError('predicate must be a function');
    }
    var list = Object(this);
    var length = list.length >>> 0;
    var thisArg = arguments[1];
    var value;

    for (var i = 0; i < length; i++) {
      value = list[i];
      if (predicate.call(thisArg, value, i, list)) {
        return value;
      }
    }
    return undefined;
  };
}

////////////////////////////////////////////
//  MASTER - UNIFICANDO COMPONENTS E JS
////////////////////////////////////////////
require('./home/loading-page'); // sections
require('../mixins/translate'); // translate
require('../mixins/pnp-mixin'); // pnp mixin
require('./new-menu-idiomas'); // menu idiomas
require('./menu-global'); // menu global
require('./unidades'); // unidades footer
require('./btn-newsletter'); // botão de newsletter
require('./menu-adm'); // menu adm
require('./menu-adm-content'); // menu adm contents pages

//scroll bg header
var $document = $(document),
  $element = $('header'),
  className = 'bg-white';

//scroll para o header
$document.scroll(function () {
  if ($document.scrollTop() >= 80) {
    // user scrolled 50 pixels or more;
    // do stuff
    $element.addClass(className);
  } else {
    $element.removeClass(className);
  }
});

function changeLanguage(value, disableReload, force) {
  if (CurrentLanguageDecimal == value && !force) {
    return Promise.resolve();
  }
  var today = new Date();
  var oneYear = new Date(today.getTime() + 365 * 24 * 60 * 60 * 1000);
  var url = window.location.href;

  if (value == 1046) url = url.replace('/en-US/', '/').replace('/en-US', '/');

  if (value == 1033 && url.indexOf('/en-US') == -1) {
    url = url.replace('.com.br', '.com.br/en-US');
  }
  //document.cookie = "lcid=" + value + ";path=/;expires=" + oneYear.toGMTString();
  if (!disableReload) {
    window.location.href = url;
  }
  Promise.resolve();
}

$(document).ready(function () {
  if (
    CurrentLanguageLCID == 'en-US' &&
    !window.location.href.includes('en-US')
  ) {
    changeLanguage('1046');
  }
  if (
    _spPageContextInfo.webAbsoluteUrl.includes('/pi') &&
    !_spPageContextInfo.webAbsoluteUrl.includes('bmapi')
  ) {
    window.location.href =
      CurrentLanguageLCID == 'en-US'
        ? `${_spPageContextInfo.webAbsoluteUrl.split('/pi')[0]}/en-US/${
            window.location.href.split('/pi/')[1]
          }`
        : `${_spPageContextInfo.webAbsoluteUrl.split('/pi')[0]}/${
            window.location.href.split('/pi/')[1]
          }`;
  }
  if (
    !window.location.href.includes('expertise-internacional') &&
    CurrentLanguageDecimal !== '1033' &&
    CurrentLanguageDecimal !== '1046'
  ) {
    var today = new Date();
    var oneYear = new Date(today.getTime() + 365 * 24 * 60 * 60 * 1000);
    var url = window.location.href;
    document.cookie = 'lcid=1046;path=/;expires=' + oneYear.toGMTString();
    window.location.href = url;
  }
});

$(document).ready(function () {
  $(this).scrollTop(0);

  if (
    ($pnp.util.getUrlParamByName('lang') &&
      $pnp.util.getUrlParamByName('lang').toLowerCase() == 'en-us') ||
    window.location.href.indexOf('/en-US') > -1
  ) {
    var today = new Date();
    var oneYear = new Date(today.getTime() + 365 * 24 * 60 * 60 * 1000);
    document.cookie = 'lcid=1033;path=/;expires=' + oneYear.toGMTString();
  }

  //endereços footer mobile - carousel bootstrap
  $('#enderecosFooter').carousel({
    interval: false,
  });

  //pegando o idioma e modificando exibição
  if (CurrentLanguageLCID == 'pt-BR') {
    $('[lang="pt-br"]').show();
    $('[lang="en-us"]').hide();
  } else {
    $('[lang="pt-br"]').hide();
    $('[lang="en-us"]').show();
  }
  // inicializando o RD Stantion
  new RDStationForms(
    'receba-nossos-informativos-3de06f66d9214bee19ed-html',
    'UA-61547807-1'
  ).createForm();
  new RDStationForms(
    'contact-bma-8e89b8ea3cd591412543-html',
    'UA-61547807-1'
  ).createForm();

  // redirecionando com param do idioma EN caso nao possua
  if (
    CurrentLanguageLCID == 'en-US' &&
    window.location.href.indexOf('/en-US') == -1
  ) {
    location.href = location.href.replace('.com.br', '.com.br/en-US');
  }
  /*
    if (CurrentLanguageLCID == 'en-US' && document.cookie.indexOf('lcid') == -1) {
        window.translateMixin.methods.changeLanguage(CurrentLanguageLCID, false, true)
    }
    */

  // aplicando cor dinâmica ao título do banner interno
  return $pnp.sp.web
    .getList(_spPageContextInfo.webServerRelativeUrl + '/Paginas')
    .items.filter(`Id eq ${_spPageContextInfo.pageItemId}`)
    .select('*, CorTexto/Cor')
    .expand('CorTexto')
    .get()
    .then((results) => {
      if ((results[0] || {}).CorTexto.Cor)
        return $('#banner .banner-interno h2').css(
          'color',
          results[0].CorTexto.Cor
        );
    });
});

Vue.config.devtools = true;
