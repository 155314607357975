require('./busca-profissionais');
require('../mixins/translate');

function menuGlobalScope(Vue, $pnp) {
  $pnp.setup({
    headers: {
      Accept: 'application/json; odata=verbose',
    },
  });

  // dropdown-menus
  Vue.component('menu-filho', {
    props: {
      items: {
        type: Array,
        default: function () {
          return [];
        },
      },
    },
    data: function () {
      return {
        list: {
          newUrl:
            _spPageContextInfo.webAbsoluteUrl + '/lists/MenuGlobal/new.aspx',
        },
      };
    },
    mixins: [translateMixin],
    methods: {
      getUrl: function (item) {
        if (item.URL) {
          if (item.URL.match(/http/i)) {
            return item.URL;
          } else {
            return this.formatUrl(item.URL);
          }
        } else {
          return 'javascript:';
        }
      },
      checkESG() {
        if (this.items[0] && this.items[0].URL.includes('/esg')) return true;
      },
      getTarget: function (item) {
        if (item.NovaJanela) {
          return '_blank';
        } else {
          return '_self';
        }
      },
    },
    template: `
		<div class="dropdown-menu" :class="checkESG() ? 'dropdown-esg' : ''">
			<a v-for="(item, index) in items" class="dropdown-item" :href="getUrl(item)" :target="getTarget(item)">{{ CurrentLanguageDecimal === '1046' ? item.Title : (item.TitleEN || item.Title) }}</a>
		</div>`,
  });

  //dropdown - mega menus
  Vue.component('mega-menu', {
    props: {
      items: {
        type: Array,
        default: function () {
          return [];
        },
      },
      parentItem: { required: true },
    },

    data() {
      return {
        list: {
          newUrl:
            _spPageContextInfo.webAbsoluteUrl + '/lists/MenuGlobal/new.aspx',
        },
      };
    },
    computed: {
      filteredItems() {
        var currentFilter = this.parentItem.FilterMenu.find(
          (filtro) => filtro.selected
        ).filter;
        return this.items.filter(currentFilter);
      },
    },
    mixins: [translateMixin],
    methods: {
      getUrl: function (item) {
        if (item.URL) {
          if (item.URL.match(/http/i)) {
            return item.URL;
          } else {
            return this.formatUrl(item.URL);
          }
        } else {
          return 'javascript:';
        }
      },
      getTarget: function (item) {
        if (item.NovaJanela) {
          return '_blank';
        } else {
          return '_self';
        }
      },
      paginateArray(array) {
        const itemsPerPage = Math.ceil(this.items.length / 3);
        return array.reduce(function (pairs, item, index) {
          var group = Math.ceil((index + 1) / itemsPerPage);
          var currentPair = (pairs[group] || []).concat(item);
          pairs[group] = currentPair;
          return pairs;
        }, {});
      },
      doFilter(filtro) {
        this.parentItem.FilterMenu.map((filterItem) => {
          Vue.set(filterItem, 'selected', false);
        });

        Vue.set(filtro, 'selected', true);
      },
    },
    template: `
		<div class="dropdown-menu" id="menu-setores-atuacao">
			<div class="filter-menu d-none" v-if="parentItem.FilterMenu">
				<span v-for="filtro in parentItem.FilterMenu">
					<a 	href="javascript:" 
						v-on:click.stop.prevent="doFilter(filtro)"
						:class="{vermelho: filtro.selected}">
						{{ CurrentLanguageDecimal === '1046' ? filtro.title : (filtro.titleEN || filtro.title) }}
					</a>
				</span>
			</div>
	
			<div class="row">	
				<ul class="col-sm-4" v-for="page in paginateArray(filteredItems)">
					<li class="dropdown-item" v-for="(child, index) in page">
						<a class="dropdown-link" :href="getUrl(child)" :target="getTarget(child)">{{ CurrentLanguageDecimal === '1046' ? (child.TitleMenu || child.Title) : ( child.TitleMenuEN || child.TitleEN || child.Title) }}</a>
					</li>
				</ul>
			</div>
		</div>`,
  });

  Vue.component('busca-profissionais-menu', {
    template: `
		<div class="dropdown-menu" id="menu-profissionais">
            <div class="menu-busca-profissionais">
                <div class="container">
                    <busca-profissionais :is-menu="true" />
                </div>
            </div>
		</div>
		`,
    mixins: [translateMixin],
    mounted() {
      var _self = this;
      window.addEventListener('click', function (e) {
        if (
          document.getElementById('menu-profissionais').contains(e.target) ||
          document.getElementById('menu-item-10').contains(e.target)
        ) {
        } else {
          if ($('#menu-profissionais').is(':visible')) {
            _self.closeMenuProfissionais();
          }
        }
      });
    },
    methods: {
      closeMenuProfissionais: function () {
        $('#menu-profissionais').stop().hide();
      },
    },
  });

  //menu global
  Vue.component('menu-global-multinivel', {
    props: {
      items: {
        type: Array,
        default: function () {
          return [];
        },
      },
      customClass: {
        type: String,
        default: '',
      },
    },
    data: function () {
      return {
        list: {
          newUrl:
            _spPageContextInfo.webAbsoluteUrl + '/lists/MenuGlobal/new.aspx',
        },
        isMobile: window.innerWidth <= 768,
        showDivSearch: false,
        keyWord: null,
        areasUrl: _spPageContextInfo.webAbsoluteUrl + '/areas-atuacao',
      };
    },
    methods: {
      onItemMouseHover: function (item) {
        if (item.children.length) {
          this.closeOpenedItems(this.items);
          Vue.set(item, 'isOpened', !item.isOpened);
        }
      },
      closeOpenedItems: function (items) {
        items.forEach(
          function (item) {
            if (item.isOpened) {
              item.isOpened = false;
              if (item.children) this.closeOpenedItems(item.children);
            }
          }.bind(this)
        );
      },
      getUrl(item) {
        if (item.URL) {
          if (item.URL.match(/http/i)) {
            const idioma = CurrentLanguageDecimal !== '1046' ? '/en-US' : '';
            if (item.URL.includes('bmapi'))
              return `${item.URL.split('/pi')[0]}${idioma}/pi`;
            else return item.URL;
          } else {
            return this.formatUrl(item.URL);
          }
        } else {
          return 'javascript:';
        }
      },
      getTarget: function (item) {
        if (item.NovaJanela) {
          return '_blank';
        } else {
          return '_self';
        }
      },
      onClickAreas(url) {
        CurrentLanguageDecimal !== '1046' ? (this.isEnglish = true) : '';
        this.changeLanguage(this.isEnglish ? 1033 : 1046);

        window.location.href = this.isEnglish
          ? `${_spPageContextInfo.webAbsoluteUrl}/en-US/areas-atuacao`
          : url;
      },
      onMenuClick(item, event) {
        const url = this.getUrl(item);
        const isNovaAba = this.getTarget(item) === '_blank';
      
        if (isNovaAba) {
          // deixa o navegador seguir o link normalmente
          return;
        }
      
        if ((item.Id == 9 || item.Id == 10) && !this.isMobile) {
          if (item.Id == 10) {
            $('#menu-profissionais').stop().show();
          } else {
            $('#menu-profissionais').stop().hide();
          }
        } else {
          CurrentLanguageDecimal !== '1046' ? (this.isEnglish = true) : '';
          window.location.href = url;
        }
      
        // previne a navegação padrão apenas se for redirecionamento manual
        event.preventDefault();
      },
      closeMenuProfissionais: function () {
        $('#menu-profissionais').stop().hide();
      },
      enableSearch(enable) {
        Vue.set(this, 'showDivSearch', enable);
        console.log(_spPageContextInfo.siteAbsoluteUrl);
      },
      redirectPageSearch() {
        if (this.keyWord) {
          if (SociedadeValue == 'LAW')
            return (window.location.href = `${_spPageContextInfo.siteAbsoluteUrl}/paginas/busca.aspx#k=${this.keyWord}`);
          else
            return (window.location.href = `${_spPageContextInfo.siteAbsoluteUrl}/busca.aspx#k=${this.keyWord}`);
        }
      },
    },
    mixins: [translateMixin],
    template: `
        <nav class="navbar navbar-expand-lg navbar-light ">
            <div class="mobile-search-div" v-if="showDivSearch">
                <div class="w-100 h-100 mobile-search">
                    <div class="d-flex form-group col-md-6 busca-50 lupa pl-1 pr-5 w-100 h-100 align-items-center justify-content-around">
                        <input v-model="keyWord" type="text" class="form-control" :placeholder="CurrentLanguageDecimal === '1046' ? 'O que você procura?' : 'Search'" @keypress.enter.prevent="redirectPageSearch()" />
                        <i class="lupa-search" @click="redirectPageSearch()"></i>
                        <div class="search-close" @click="enableSearch(false)"><i class="fas fa-times"></i></div>
                    </div>
                </div>
            </div>
          <a class="nav-brand mr-4 logo-mobile" :href="formatUrl('')" title="BMA"></a>
          <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav mr-auto">
              <li class="nav-item text-center" v-for="(item, index) in items">
                <!-- Alteração aqui para tratar o Id == 9 -->
                <a v-if="item.Id == 9" class="nav-link"  :style="item.Destaque ? 'color:#eb1e28 !important;' : ''" href="javascript:"  @click="onClickAreas(areasUrl)">
                {{ CurrentLanguageDecimal === '1046' ? item.Title : item.TitleEN }}
                </a>
                <a v-else class="nav-link" :id="'menu-item-'+item.Id" 
                  :style="item.Destaque ? 'color:#eb1e28 !important;' : ''"
                  :href="getUrl(item)" :target="getTarget(item)"
                  :data-toggle="(item.children.length && !isMobile) || item.Id == 2 ? 'dropdown' : ''" 
                  :aria-expanded="item.children && 'false'" @click="onMenuClick(item)">
                  {{ CurrentLanguageDecimal === '1046' ? item.Title : item.TitleEN }}
                </a>
                <template v-if="item.Id == 10 && !isMobile">
                  <busca-profissionais-menu></busca-profissionais-menu>
                </template>
                <template v-else-if="item.children.length && item.Id !== 9">
                  <menu-filho :items="item.children"></menu-filho>
                </template>
              </li>
            </ul>
                    
          </div>
            <new-menu-idiomas @search="enableSearch(true)" />
		</nav>`,
  });

  Vue.component('menu-component', {
    mixins: [translateMixin],
    data: function () {
      return {
        items: [],
        isOpened: false,
        isUserAllowed: false,
      };
    },
    created: function () {
      this.getItems(
        _spPageContextInfo.siteServerRelativeUrl,
        _spPageContextInfo.siteAbsoluteUrl
      )
        .then(this.getAreas)
        //.then(this.getSetores)
        //.then(this.getDesks)
        .then(this.parseLevels)
        .then(this.setItems);
    },
    mounted: function () {
      document.onclick = this.onClickOutside;
    },
    methods: {
      getAreas(menu) {
        // área de atuação
        var titulo = CurrentLanguageDecimal !== '1046' ? 'TitleEN' : 'Title';
        const idPai = 9;
        var sociedade = SociedadeValue;
        var web = new $pnp.Web(_spPageContextInfo.siteAbsoluteUrl);
        return web
          .getList(
            `${_spPageContextInfo.siteServerRelativeUrl}Lists/AreasAtuacao`
          )
          .items.select('*')
          .filter(`AuxSociedade eq '${sociedade}' and Ativo eq 1`)
          .top(100)
          .orderBy(`${titulo}`)
          .get()
          .then((areas) => {
            areas = areas
              .filter((area) => !area.ItemPaiId)
              .map((area) => {
                area.IdArea = area.Id;
                area.SeoURL = area.SeoURL;
                area.ID = area.Id = `area${area.IdArea}`;

                return R.merge(area, {
                  ItemPaiId: idPai,
                  ItemPrincipalId: idPai,
                  URL: `areas-atuacao/${area.SeoURL}`,
                });
              });

            menu = this.buildMenuFilters(menu);
            menu = menu.concat(areas);

            return menu;
          });
      },
      getSetores(menu) {
        // área de atuação
        const idPai = 9;

        var web = new $pnp.Web(_spPageContextInfo.siteAbsoluteUrl);
        return web
          .getList(`${_spPageContextInfo.siteServerRelativeUrl}Lists/Setores`)
          .items.select('*')
          .top(100)
          .filter('Ativo eq 1')
          .orderBy(CurrentLanguageDecimal !== '1046' ? 'TituloEn' : 'Title')
          .get()
          .then((setores) => {
            setores = setores.map((setor) => {
              setor.IdSetor = setor.Id;
              setor.SeoURL = setor.SeoURL;
              setor.ID = setor.Id = `setor${setor.IdSetor}`;

              return R.merge(setor, {
                ItemPaiId: idPai,
                ItemPrincipalId: idPai,
                URL: `/paginas/setor-detalhe.aspx?setor=${setor.SeoURL}`,
              });
            });

            menu = this.buildMenuFilters(menu);
            menu = menu.concat(setores);

            return menu;
          });
      },
      getDesks(menu) {
        const idPai = 9;

        var web = new $pnp.Web(_spPageContextInfo.siteAbsoluteUrl);
        return web
          .getList(`${_spPageContextInfo.siteServerRelativeUrl}Lists/Desks`)
          .items.select('*')
          .top(100)
          .filter('Ativo eq 1')
          .orderBy('Ordem')
          .get()
          .then((desks) => {
            desks = desks.map((desk) => {
              desk.IdDesk = desk.Id;
              desk.SEOurl = desk.SEOurl;
              desk.ID = desk.Id = `desk${desk.IdDesk}`;

              return R.merge(desk, {
                temPaiId: idPai,
                ItemPrincipalId: idPai,
                URL: `/paginas/desk.aspx?desk=${desk.SEOurl}`,
              });
            });

            menu = this.buildMenuFilters(menu);
            menu = menu.concat(desks);

            return menu;
          });
      },
      buildMenuFilters(menu) {
        const idArea = 9;

        return menu.map((item) => {
          if (item.ID == idArea) {
            item.FilterMenu = [
              {
                title: 'Áreas',
                titleEN: 'Areas',
                selected: true,
                filter: (item) => {
                  return item.IdArea;
                },
              },
              /*{
                                title: 'Setores',
                                titleEN: 'Sectors',
                                selected: false,
                                filter: (item) => { return item.IdSetor }
                            }
                            {
                                title: 'Desks',
                                selected: false,
                                filter: (item) => { return item.IdDesk }
                            }*/
            ];
          }
          return item;
        });
      },
      onClickOutside: function () {
        this.openMenu(false);
      },
      getItems: function (webRelative, webAbsolute) {
        var web = new $pnp.Web(webAbsolute);
        var sociedade = SociedadeValue;
        return web
          .getList(webRelative + '/lists/MenuGlobal')
          .items.filter(`AuxSociedade eq '${sociedade}'`)
          .top(500)
          .orderBy('Ordem')
          .get();
      },
      setItems: function (items) {
        this.items = items;
      },
      getChildren: function (items, parentId) {
        return items
          .filter(function (item) {
            return parentId === item.ItemPrincipalId;
          })
          .map(function (item) {
            item.levelId = item.ItemPrincipalId;
            return item;
          });
      },
      clone: function (obj) {
        if (null == obj || 'object' != typeof obj) return obj;
        var copy = obj.constructor();
        for (var attr in obj) {
          if (obj.hasOwnProperty(attr)) copy[attr] = obj[attr];
        }
        return copy;
      },
      cloneArray: function (array) {
        return JSON.parse(JSON.stringify(array));
      },
      parseLevels: function (items = []) {
        var reduceLevels = function (levelAtual, curItem, index) {
          // é necessário clonar pois propriedades como levelId e children são alteradas nos loops
          // quando não clonamos, corremos o risco de perder a referencia de levelId por exemplo
          var allItems = this.cloneArray(items);

          if (
            curItem.levelId == curItem.ItemPrincipalId ||
            curItem.ID == curItem.levelId
          ) {
            curItem.levelId = curItem.ID;
            curItem.children = this.getChildren(allItems, curItem.ID).reduce(
              reduceLevels.bind(this),
              []
            );
            levelAtual.push(curItem);
          }
          return levelAtual;
        };

        items = items.reduce(reduceLevels.bind(this), []);
        return items;
      },
      onMenuClick: function () {
        this.openMenu(!this.isOpened);
      },
      openMenu: function (open) {
        this.isOpened = open;
      },
      closeMenuProfissionais: function () {
        $('#menu-profissionais').stop().hide();
      },
    },
    template: `
		<div id="menu">
			<menu-global-multinivel :items="items" />
		</div>`,
  });

  new Vue({
    el: '#menu-component',
    template: '<menu-component/>',
  });
}
menuGlobalScope(Vue, $pnp);
