$pnp.setup({
  sp: {
    headers: {
      Accept: 'application/json; odata=verbose',
    },
  },
});

Vue.component('component-unidades', {
  mixins: [translateMixin],
  template: `
        <element>
            <div id="enderecosFooter" class="carousel slide enderecos-footer" data-ride="carousel">
            <ol class="carousel-indicators d-sm-none">
                <li data-target="#enderecosFooter" :data-slide-to="index" v-for="(item, index) in unidades" :class="{active: index == 0}">{{ item.Sigla }}</li>
            </ol>
            <div class="carousel-inner row">
                <div class="carousel-item col-12 col-sm-4" v-for="(item, index) in unidades" :class="{active: index == 0}">
                    <h4 class="text-uppercase"><i class="fa fa-map-marker-alt text-primary mr-1"></i> {{ item.Title }}</h4>
                    <p class="end mb-0">
                    <span v-html="item.Descricao"></span>
                    <a :href="item.URL" target="blank" :title="currentLang === '1046' ? 'veja o mapa' : 'See Map'" class="text-tertiary teste">{{ currentLang === '1046' ? '[veja o mapa]' : '[see map]' }}</a>
                    </p>
                    <span class="sep-end"></span>
                    <div class="row">
                        <div v-if="item.Telefone" class="col-6 col-sm-12 pr-0">
                            <p v-if="!isMobile" class="mb-0"><span class="text-tertiary">T</span> {{ item.Telefone }}</p>
                            <a v-else class="mb-0" :href="'tel:' + item.Telefone"><span class="text-tertiary">T</span> {{ item.Telefone }}</a>
                        </div>
                        <div v-if="item.Fax" class="col-6 col-sm-12 pr-0">
                            <p class="mb-0"><span class="text-tertiary">F</span> {{ item.Fax }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </element>
    `,
  created: function () {
    this.getData()
      .then(
        this.applyGetData,
        this.threatError.bind(
          this,
          'erro ao obter informações da lista ' + this.listName + ''
        )
      )
      .then(this.applyEvents);
  },
  methods: {
    getData: function () {
      var isMobile = window.innerWidth < 768,
        ordem = 'Ordem';
      if (isMobile) {
        ordem = 'OrdemMobile';
      }

      var web = new $pnp.Web(_spPageContextInfo.siteAbsoluteUrl);
      return (
        web
          .getList(this.listUrl)
          .items.filter(`AuxSociedade/Title eq '${SociedadeValue}'`)
          .select('*, AuxSociedade/Title')
          .expand(`AuxSociedade`)
          .orderBy(ordem, true)
          //.top()
          .usingCaching()
          .get()
      );
    },
    applyGetData: function (data) {
      this.unidades = data;
    },
    applyEvents: function () {},
    threatError: function (msg, data) {
      console.error('error - ' + msg + ' - ' + data);
    },
  },
  data: function () {
    var listName = 'Unidades';
    return {
      listName: listName,
      listUrl:
        _spPageContextInfo.siteServerRelativeUrl + '/lists/' + listName + '',
      unidades: [],
      isMobile: window.innerWidth < 768,
    };
  },
});

var app = new Vue({
  el: '#component-unidades',
  template: '<component-unidades/>',
});
