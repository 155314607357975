Vue.component('new-menu-idiomas', {
  mixins: [translateMixin],
  template: `
    <div v-if="languages">
        <div>
            <div class="custom-dropdown" @click="toggleDropdown">
                <img :src="getImage(selectedLanguage)" :alt="getAlt(selectedLanguage)" width="50%" height="50%" />
                {{ getLanguage(selectedLanguage) }}
                    <div v-if="dropdownOpen" class="dropdown-content show py-2">
                        <div v-for="language in filteredLanguages" v-if="checkIsPI(language)" :key="language.value" @click="selectLanguage(language)" class="child-content">
                            <img :src="language.LinkImagem" :alt="language.alt" />
                            {{ language.text }}
                        </div>
                    </div>
            </div>
        </div>
    </div>
    `,
  data() {
    return {
      dropdownOpen: false,
      lastSelected: null,
      selectedLanguage: CurrentLanguageDecimal,
      expertise: `${_spPageContextInfo.webAbsoluteUrl}/expertise-internacional`,
      listUrl: `${_spPageContextInfo.siteServerRelativeUrl}/Lists/IdiomasMenu`,
      languages: null,
    };
  },
  computed: {
    filteredLanguages() {
      return this.languages.filter(
        (lang) => parseFloat(lang.value) != this.selectedLanguage
      );
    },
  },
  created() {
    this.getLanguages();
  },
  methods: {
    getLanguages() {
      var web = new $pnp.Web(_spPageContextInfo.siteAbsoluteUrl);
      return web
        .getList(this.listUrl)
        .items.select('*, link/SeoURL, link/Desk')
        .expand('link')
        .filter('Ativo eq 1')
        .orderBy('Ordem', true)
        .get()
        .then((res) => {
          this.languages = res;
        });
    },
    toggleDropdown() {
      this.dropdownOpen = !this.dropdownOpen;
    },
    isSelected(value) {
      return this.lastSelected === value;
    },
    selectLanguage(language) {
      this.lastSelected = language.value;
      this.selectedLanguage = language.value;
      if (
        window.location.href.includes(this.expertise) &&
        !language.link.SeoURL
      ) {
        return this.changeLanguage(this.selectedLanguage, true).then(() => {
          return (window.location.href = `${_spPageContextInfo.webAbsoluteUrl}`);
        });
      } else {
        this.onChangeLanguage();
        this.dropdownOpen = false;
        if (language.link.SeoURL) {
          window.location.href = `${_spPageContextInfo.webAbsoluteUrl}/expertise-internacional/${language.link.SeoURL}/${language.link.Desk}`;
        }
      }
    },
    getImage(value) {
      const language = this.languages.find(
        (lang) => parseFloat(lang.value) === parseFloat(value)
      );
      return language ? `${language.LinkImagem}` : '';
    },
    getLanguage(value) {
      const language = this.languages.find(
        (lang) => parseFloat(lang.value) === parseFloat(value)
      );
      return language ? language.text : '';
    },
    getAlt(value) {
      const language = this.languages.find(
        (lang) => parseFloat(lang.value) === parseFloat(value)
      );
      return language ? language.alt : '';
    },
    onClickSearch() {
      this.$emit('search');
    },
    mudarIdioma(lcid) {
      this.changeLanguage(lcid);
    },
    onChangeLanguage() {
      this.mudarIdioma(this.selectedLanguage);
    },
    checkIsPI(language) {
      return (
        (window.location.href.includes('/pi') && !language.link.SeoURL) ||
        !window.location.href.includes('/pi')
      );
    },
  },
  mounted() {
    document.addEventListener('click', (event) => {
      if (!this.$el.contains(event.target)) {
        this.dropdownOpen = false;
      }
    });
  },
  beforeDestroy() {
    document.removeEventListener('click', this.handleOutsideClick);
  },
});
